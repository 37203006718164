import { useQuery } from '@apollo/client'
import { GET_TEAM_POOPS } from '../../queries/teamQueries'
import { DataTable, Main, Box, Spinner, Paragraph } from 'grommet'


export default function TeamPoops() {
  const { loading, error, data } = useQuery(GET_TEAM_POOPS)
  if(loading) {
    <p>Loading 💩...</p>
  }
  if(error) {
    <p>Issue....</p>
  }

  
  let columns = [
    {
      property: 'team',
      header: "Team"
    },
    {
      property: 'poops',
      header: "Poopy Basements"
    },
    {
      property: 'low',
      header: 'Lowest'
    }
  ]
  
  return (
    <Main>
      {loading && (
        <Box justify='center' align='center'>
          <Spinner size="medium" color='graph-2' />
        </Box>
      )}
      {!loading && !error && (
        <>
          <h1>💩 Basements</h1>
          <Paragraph size="small" margin={{vertical: 'none', right: 'none', left: 'xsmall'}}>
              (1/2 ppr started in 2014)
            </Paragraph>
          <Box
            overflow={{
              vertical: "hidden",
              horizontal: "auto"
            }}
            margin={{
              vertical: "medium"
            }}
          >
            <DataTable
              sortable={true}
              columns={columns}
              data={data.teamPoops} 
              border={
                {header: "bottom",
                body: "horizontal",
                footer: "bottom"}
              }
              background={
                {
                  header: {dark: "purple", light: "graph-2"}
                }
              }
            />
          </Box>
        </>
      )}
    </Main>
  )
}
