import { useQuery } from '@apollo/client'
import { GET_TEAM_BY_ID } from '../../queries/teamQueries'
import { DataTable, Main, Box, Spinner } from 'grommet'



export default function SingleTeam({ teamId }) {
  const { loading, error, data } = useQuery(GET_TEAM_BY_ID, 
    {
      variables: { teamId }
    })

  if(loading) {
    <p>Loading...</p>
  }
  if(error) {
    <p>Issue....</p>
  }
  const ownerName = !loading && data ? data.team[0].ownerName : ''
  const [fName] = ownerName ? ownerName.split(' ') : ''

  const columns = [
    {
      property: 'ownerName',
      header: 'Manager (Team Name)',
      primary: true,
      pin: true,
      render: datum => (
        <>
          {`${fName} (${datum.location} ${datum.nickname})`}
        </>
      )
    },
    {
      property: 'seasonId',
      header: 'Season'
    },
    {
      property: 'record.overall.wins',
      header: 'W-L',
      render: datum => (
        <>
          {datum.record.overall.wins}-{datum.record.overall.ties}-{datum.record.overall.losses}
        </>
      )
    },
    {
      property: 'record.overall.percentage',
      header: 'Win%',
      render: datum => (
        <>{datum.record.overall.percentage.toFixed(2)}</>
      )
    },
    {
      property: 'record.overall.pointsFor',
      header: 'Points'
    },
    {
      property: 'record.overall.pointsAgainst',
      header: 'Allowed'
    },
    {
      property: 'playoffSeed',
      header: 'Seed'
    },
    {
      property: 'transactionCounter.trades',
      header: 'Trades'
    },
    {
      property: 'transactionCounter.acquisitions',
      header: 'Acq'
    },
    {
      property: 'draftPosition',
      header: 'Draft Pos'
    }
  ]

  return (
    <Main>
      {loading && (
        <Box justify='center' align='center'>
          <Spinner size="medium" color='graph-2' />
        </Box>
      )}
      {!loading && !error && (
        <>
        <h1>{ownerName}</h1>
        <Box
            overflow={{
              vertical: "hidden",
              horizontal: "auto"
            }}
            margin={{
              vertical: "medium"
            }}
          >
        <DataTable
          sortable={true}
          columns={columns}
          data={data.team} 
          border={
            {header: "bottom",
            body: "horizontal",
            footer: "bottom"}
          }
          background={
            {
              header: {dark: "purple", light: "graph-2"}
            }
          }
        />
        </Box>
        </>
      )}
    </Main>
  )
}
