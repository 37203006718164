import { useQuery } from '@apollo/client'
import { GET_TEAM_TOTALS } from '../../queries/teamQueries'
import { DataTable, Main, Box, Spinner, Paragraph } from 'grommet'


export default function TeamTotals() {
  const { loading, error, data } = useQuery(GET_TEAM_TOTALS)
  if(loading) {
    <p>Loading Team Totals...</p>
  }
  if(error) {
    <p>Issue....</p>
  }
  const columns = [
    {
      property: 'firstName',
      header: 'Manager',
      primary: true,
      pin: true
    },
    {
      property: 'averageFinish',
      header: 'Avg Finish'
    },
    {
      property: 'totalWins',
      header: 'Wins'
    },
    {
      property: 'totalLosses',
      header: 'Losses'
    },
    {
      property: 'totalTies',
      header: 'Ties'
    },
    {
      property: 'totalWinPercentage',
      header: 'Win%'
    },
    {
      property: 'bestRecord',
      header: 'Best W-L'
    },
    {
      property: 'mostPointsInSeason',
      header: 'Most Points'
    },
    {
      property: 'totalPointsFor',
      header: 'Total Points'
    },
    {
      property: 'totalPointsAgainst',
      header: 'Total Allowed'
    },
    {
      property: 'averagePointsFor',
      header: 'Avg Points'
    },
    {
      property: 'averagePointsAgainst',
      header: 'Avg PA'
    },
    {
      property: 'seasonBestPD',
      header: 'Best PD'
    },
    {
      property: 'seasonWorstPD',
      header: 'Worst PD'
    },
    {
      property: 'careerPD',
      header: 'Career PD'
    },
    {
      property: 'totalSeasons',
      header: 'Seasons'
    },
    {
      property: 'totalMatchesPlayed',
      header: 'Games'
    },
    {
      property: 'averagePointsForMatch',
      header: 'Avg PPM'
    },
    {
      property: 'averagePointsAgainstMatch',
      header: 'Avg PAPM'
    },
    {
      property: 'totalPlayOffBirths',
      header: 'Playoffs'
    },
    {
      property: 'totalChampionships',
      header: 'Ships'
    },
    {
      property: 'bestFinishRegular',
      header: 'Best Regular Finish'
    },
    {
      property: 'worstFinishRegular',
      header: 'Worst Regular Finish'
    },
    {
      property: 'bestFinishPlayoffs',
      header: 'Best Playoffs'
    },
    {
      property: 'totalTrades',
      header: 'Trades'
    },
    {
      property: 'totalAcquisitions',
      header: 'Acqs'
    },
  ]

  return (
    <Main>
      {loading && (
        <Box justify='center' align='center'>
          <Spinner size="medium" color='graph-2' />
        </Box>
      )}
      {!loading && !error && (
        <>
          <h1>Team Totals (2012-2022)</h1>
          <Paragraph size="small" margin={{vertical: 'none', right: 'none', left: 'xsmall'}}>
              (click table heading to sort by column)
            </Paragraph>
          <Box
            overflow={{
              vertical: "hidden",
              horizontal: "auto"
            }}
            margin={{
              vertical: "medium"
            }}
          >
            <DataTable
              sortable={true}
              columns={columns}
              data={data.teamTotals} 
              border={
                {header: "bottom",
                body: "horizontal",
                footer: "bottom"}
              }
              background={
                {
                  header: {dark: "purple", light: "graph-2"}
                }
              }
            />
          </Box>
        </>
      )}
    </Main>
  )
}
