import React, { useState } from 'react'
import { postGameData } from '../postgamedata'
import { postGameSeason } from '../postgameseasondata'
import { Main, Box, Card, Select, Text } from 'grommet'

const PostGame = () => {
  const options = [
    { label: 'Season to Date', value: -1},
    { label: 'Week 1', value: 0},
    { label: 'Week 2', value: 1},
    { label: 'Week 3', value: 2},
    { label: 'Week 4', value: 3},
    { label: 'Week 5', value: 4},
    { label: 'Week 6', value: 5},
    { label: 'Week 7', value: 6},
    { label: 'Week 8', value: 7},
    { label: 'Week 9', value: 8},
    { label: 'Week 10', value: 9},
    { label: 'Week 11', value: 10},
    { label: 'Week 12', value: 11},
    { label: 'Week 13', value: 12}
  ]
  const [value, setValue] = useState(postGameData.length - 1 || 0);
  const [awardsData, setAwardsData] = useState(postGameData[value] || postGameData[0])
  
  return (
    <Main>
      <Box pad='small'>
        <h1>The Post Game Landing</h1>
        <h3>
        <Select
          id="select"
          name="select"
          placeholder="Select"
          labelKey="label"
          valueKey={{ key: 'value', reduce: true }}
          value={value}
          options={options}
          onChange={({ value: nextValue }) => {
            setValue(nextValue)
            nextValue === -1 ? setAwardsData(postGameSeason[0]) : setAwardsData(postGameData[nextValue])
          }}
        />
        </h3>
      </Box>
      <Box>
        {awardsData.awards.map((award, index) => {
          return (
         <Card key={`card${index}`} pad="small" background="light-1" margin="small">
            <Box align='start' direction='row'>
              <Box margin="small" className='awardsLogoContainer' >
                <img className='awardsLogo' src={award.logo} alt="Logo for S" />
              </Box>
              <Box margin="small">
                <h3>{award.title} <span className='awardEmoji'>{award.emoji}</span></h3>
                <Text>
                  {award.descriptions.map((text, index) => {
                    return (
                        <span key={`atext${index}`} className={index === 1 || index === 3 ? 'awardStrong': ''}>{text}</span>
                    )
                  })}
                </Text>
              </Box>
            </Box>
          </Card>
        )}
        )}
        
      </Box>
    </Main>
  )
}

export default PostGame