import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from './pages/Layout'
import Home from './pages/Home'
import PostGame from './pages/PostGame'
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'
// import { deepMerge } from 'grommet/utils'


const dev = false
const prodUrl = "https://rocketsff-api.herokuapp.com/graphql"

const client = new ApolloClient({
  uri: dev ? 'http://localhost:5000/graphql' : prodUrl,
  cache: new InMemoryCache()
})

// const rocketsTheme = deepMerge(hpe, {
//   dataTable: {
//     header: {
//       background: {
//         light: "graph-2",
//         dark: "purple"
//       }
//     }
//   }
// })

function App() {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="postgame" element={<PostGame />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
