import { Outlet, Link, useLocation } from 'react-router-dom'
import { Grommet, Footer, Text, Header, Page, PageContent } from 'grommet'
import { hpe } from 'grommet-theme-hpe'
import { Deploy } from 'grommet-icons'

const Layout = () => {
  const location = useLocation()
  return (
    <Grommet full theme={hpe} themeMode="dark">
      <Header pad="small">
        <h1>
          <Text  margin={{right: "small"}}><Deploy size="large" /> </Text> Rockets Fantasy Football <br />
          {location.pathname === '/' ? <Link to="/postgame" >Post Game</Link> : <Link to="/">Home</Link>}
        </h1>
      </Header>
      <Page kind="full">
        <PageContent pad="xsmall">
          <Outlet />
        </PageContent>
      </Page>
      <Footer justify='center' pad='small' margin={{top: 'medium', bottom: 'medium'}}>
        <Text textAlign='center' size='small'>
          Selmaville Rockets Fantasy Football League Stats 2012-2022
        </Text>
      </Footer>
      </Grommet>
  )
}

export default Layout