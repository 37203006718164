import { gql } from '@apollo/client'
const GET_TEAM_TOTALS = gql`
  query getTeamTotals {
    teamTotals {
      id
      name
      firstName
      lastName
      totalWins
      totalLosses
      totalTies
      totalWinPercentage
      totalMatchesPlayed
      totalSeasons
      totalPointsFor
      totalPointsAgainst
      averagePointsFor
      averagePointsAgainst
      averagePointsForMatch
      averagePointsAgainstMatch
      careerPD
      totalPlayOffBirths
      totalChampionships
      totalTopThreePlayoffs
      bestFinishRegular
      worstFinishRegular
      bestFinishPlayoffs
      totalAcquisitions
      totalTrades
      mostPointsInSeason
      averageFinish
      bestRecord
      seasonBestPD
      seasonWorstPD
    }
  }
`

const GET_TEAM_BY_ID = gql `
  query getTeamById($teamId: String) {
    team(teamId: $teamId) {
      id
      seasonId
      primaryOwner
      owners
      abbrev
      nickname
      location
      playoffSeed
      rankCalculatedFinal
      record {
        overall{
          wins
          losses
          ties
          percentage
          pointsFor
          pointsAgainst
        }
      }
      transactionCounter {
        acquisitionBudgetSpent
        acquisitions
        trades
      }
      draftPosition
      ownerName
    }
  }
`

const GET_POOPY_BASEMENT = gql `
  query getPoopyBasement {
    poopyBasement {
      id
      weeks{
        seasonId
        week
        team
        points
      }
    }
  }
`

const GET_TEAM_POOPS = gql `
  query getTeamPops {
    teamPoops {
      team
      poops
      low
    }
  }
`

export { GET_TEAM_TOTALS, GET_TEAM_BY_ID, GET_POOPY_BASEMENT, GET_TEAM_POOPS }