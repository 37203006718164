import TeamTotals from './TeamTotals/TeamTotals'
import SingleTeam from './SingleTeam/SingleTeam'
import PoopyBasement from './PoopyBasement/PoopyBasement'
import TeamPoops from './TeamPoops/TeamPoops'
import { Main } from 'grommet'

export default function Teams() {
  return (
    <Main>
      <PoopyBasement />
      <TeamPoops />
      <TeamTotals />
      <SingleTeam teamId={"{7E492D21-9977-4BB7-A2B3-9A63E17ED69B}"} />
      <SingleTeam teamId={"{9A63F48F-7FCC-4750-AB11-8DAABCAD3BEE}"} />
      <SingleTeam teamId={"{0FEF06E2-1732-4E5C-9E02-26C0F2286A98}"} />
      <SingleTeam teamId={"{6202F588-8837-4B2C-9E6F-D1071175398A}"} />
      <SingleTeam teamId={"{BC0D1819-421A-4F6A-920D-8A2454E91BB6}"} />
      <SingleTeam teamId={"{16306E37-3A98-4214-B9F4-01CBF383EC0A}"} />
      <SingleTeam teamId={"{A3291A56-0A4C-4D62-93A0-DE128A6DD17F}"} />
      <SingleTeam teamId={"{9F3523E6-FBC7-4DB5-B22B-FB8A6BB3B58A}"} />
      <SingleTeam teamId={"{9544941E-32BD-4986-8AAC-29881C51BBA3}"} />
      <SingleTeam teamId={"{58C94EB0-3DEA-479E-9FE1-97A74FFFA81B}"} />
      <SingleTeam teamId={"{F7D3E2DB-11A4-44AB-B21F-5F421A67E4B8}"} />
      <SingleTeam teamId={"{B489B74F-A010-4284-A987-77C893340F2D}"} />
   </Main>
  )
}
