import { useQuery } from '@apollo/client'
import { GET_POOPY_BASEMENT } from '../../queries/teamQueries'
import { DataTable, Main, Box, Spinner, Paragraph, Table, TableBody, TableRow, TableCell } from 'grommet'


export default function PoopyBasement() {
  const { loading, error, data } = useQuery(GET_POOPY_BASEMENT)
  if(loading) {
    <p>Loading 💩...</p>
  }
  if(error) {
    <p>Issue....</p>
  }

  let columns = [
    {
      property: 'Week',
      header: "Week",
      pin: true,
      render: datum => {
        if(datum.weeks[10]) {
          return (
            <>
              
              {datum.weeks[10].seasonId === '2022' && datum.id !== 'lowest' && `${datum.weeks[10].week}`}
              {datum.weeks[10].seasonId === '2022' && datum.id === 'lowest' && `Season Low`}
            </>
          
        )
        } else {
          return (<></>)
        }
        
      }
    },
    {
      property: '2022',
      header: "2022",
      render: datum => {
        if(datum.weeks[10]) {
          return (
            <>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align='left'>
                      {datum.weeks[10].seasonId === '2022' && `${datum.weeks[10].team}: `}
                    </TableCell>
                    <TableCell align="right">
                      {datum.weeks[10].seasonId === '2022' && `${datum.weeks[10].points}`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </>
        )
        } else {
          return (<></>)
        }
        
      }
    },
    {
      property: '2021',
      header: "2021",
      render: datum => {
        if(datum.weeks[9]) {
          return (
            <>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align='left'>
                      {datum.weeks[9].seasonId === '2021' && `${datum.weeks[9].team}: `}
                    </TableCell>
                    <TableCell align="right">
                      {datum.weeks[9].seasonId === '2021' && `${datum.weeks[9].points}`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </>
        )
        } else {
          return (<></>)
        }
        
      }
    },
    {
      property: '2020',
      header: "2020",
      render: datum => {
        if(datum.weeks[8]) {
          return (
            <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align='left'>
                    {datum.weeks[8].seasonId === '2020' && `${datum.weeks[8].team}:`}
                    </TableCell>
                    <TableCell align="right">
                    {datum.weeks[8].seasonId === '2020' && `${datum.weeks[8].points}`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
          
        )
        } else {
          return (<></>)
        }
        
      }
    },
    {
      property: '2019',
      header: "2019",
      render: datum => {
        if(datum.weeks[7]) {
          return (
            <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align='left'>
                    {datum.weeks[7].seasonId === '2019' && `${datum.weeks[7].team}:`}
                    </TableCell>
                    <TableCell align="right">
                    {datum.weeks[7].seasonId === '2019' && `${datum.weeks[7].points}`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

          
        )
        } else {
          return (<></>)
        }
        
      }
    },
    {
      property: '2018',
      header: "2018",
      render: datum => {
        if(datum.weeks[6]) {
          return (
            <Table>
            <TableBody>
              <TableRow>
                <TableCell align='left'>
                {datum.weeks[6].seasonId === '2018' && `${datum.weeks[6].team}:`}
                </TableCell>
                <TableCell align="right">
                {datum.weeks[6].seasonId === '2018' && `${datum.weeks[6].points}`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
 
          
        )
        } else {
          return (<></>)
        }
        
      }
    },
    {
      property: '2017',
      header: "2017",
      render: datum => {
        if(datum.weeks[5]) {
          return (
            <Table>
            <TableBody>
              <TableRow>
                <TableCell align='left'>
                {datum.weeks[5].seasonId === '2017' && `${datum.weeks[5].team}:`}
                </TableCell>
                <TableCell align="right">
                {datum.weeks[5].seasonId === '2017' && `${datum.weeks[5].points}`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          
        )
        } else {
          return (<></>)
        }
        
      }
    },
    {
      property: '2016',
      header: "2016",
      render: datum => {
        if(datum.weeks[4]) {
          return (
            <Table>
            <TableBody>
              <TableRow>
                <TableCell align='left'>
                {datum.weeks[4].seasonId === '2016' && `${datum.weeks[4].team}:`}
                </TableCell>
                <TableCell align="right">
                {datum.weeks[4].seasonId === '2016' && `${datum.weeks[4].points}`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
      
          
        )
        } else {
          return (<></>)
        }
        
      }
    },
    {
      property: '2015',
      header: "2015",
      render: datum => {
        if(datum.weeks[3]) {
          return (
            <Table>
            <TableBody>
              <TableRow>
                <TableCell align='left'>
                {datum.weeks[3].seasonId === '2015' && `${datum.weeks[3].team}:`}
                </TableCell>
                <TableCell align="right">
                {datum.weeks[3].seasonId === '2015' && `${datum.weeks[3].points}`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
    
          
        )
        } else {
          return (<></>)
        }
        
      }
    },
    {
      property: '2014',
      header: "2014",
      render: datum => {
        if(datum.weeks[2]) {
          return (
            <Table>
            <TableBody>
              <TableRow>
                <TableCell align='left'>
                {datum.weeks[2].seasonId === '2014' && `${datum.weeks[2].team}:`}
                </TableCell>
                <TableCell align="right">
                {datum.weeks[2].seasonId === '2014' && `${datum.weeks[2].points}`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          
        )
        } else {
          return (<></>)
        }
        
      }
    },
    {
      property: '2013',
      header: "2013",
      render: datum => {
        if(datum.weeks[1]) {
          return (
            <Table>
            <TableBody>
              <TableRow>
                <TableCell align='left'>
                {datum.weeks[1].seasonId === '2013' && `${datum.weeks[1].team}:`}
                </TableCell>
                <TableCell align="right">
                {datum.weeks[1].seasonId === '2013' && `${datum.weeks[1].points}`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          
        )
        } else {
          return (<></>)
        }
        
      }
    },
    {
      property: '2012',
      header: "2012",
      render: datum => (
        <Table>
        <TableBody>
          <TableRow>
            <TableCell align='left'>
            {datum.weeks[0].seasonId === '2012' && `${datum.weeks[0].team}:`}
            </TableCell>
            <TableCell align="right">
            {datum.weeks[0].seasonId === '2012' && `${datum.weeks[0].points}`}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
   
      )
    }
  ]
  
  return (
    <Main>
      {loading && (
        <Box justify='center' align='center'>
          <Spinner size="medium" color='graph-2' />
        </Box>
      )}
      {!loading && !error && (
        <>
          <h1>💩 Basement (2012-2022)</h1>
          <Paragraph size="small" margin={{vertical: 'none', right: 'none', left: 'xsmall'}}>
              (1/2 ppr started in 2014)
            </Paragraph>
          <Box
            overflow={{
              vertical: "hidden",
              horizontal: "auto"
            }}
            margin={{
              vertical: "medium"
            }}
          >
            <DataTable
              sortable={false}
              columns={columns}
              data={data.poopyBasement} 
              border={
                {header: "bottom",
                body: "horizontal vertical",
                footer: "bottom"}
              }
              background={
                {
                  header: {dark: "purple", light: "graph-2"}
                }
              }
            />
          </Box>
        </>
      )}
    </Main>
  )
}
